import { SignatureRegular } from "@fluentui/react-icons";

//this sets the text where your cursor is located
export const insertTextAsync = async (text) => {
  try {
    await Office.context.mailbox.item.body.setSelectedDataAsync(
      text,
      { coercionType: Office.CoercionType.Html }
    );
  } catch (error) {
    console.error("Error: " + error.message);
    throw error;
  }
};

//sets entire body
export const setBodyHtmlAsync = async (text) => {
  try {
    await Office.context.mailbox.item.body.setAsync(
      text,
      { coercionType: Office.CoercionType.Html }
    );
  } catch (error) {
    console.error("Error: " + error.message);
    throw error;
  }
};

export const getSelectedText = () => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.getSelectedDataAsync(
      Office.CoercionType.Text,
      (result) => {
        try {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            const selectedText = result.value;

            resolve(selectedText);
          } else {
            const errorMessage = 'Error getting selected text: ' + result.error.message;
            console.error(errorMessage);
            reject(new Error(errorMessage));
          }
        } catch (error) {
          console.error('Error:', error.message);
          reject(error);
        }
      }
    );
  });
};

export const getBodyAsync = () => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(
      Office.CoercionType.Text,
      (result) => {
        try {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            const currentBody = result.value;

            resolve(currentBody);
          } else {
            const errorMessage = 'Error getting body: ' + result.error.message;
            console.error(errorMessage);
            reject(new Error(errorMessage));
          }
        } catch (error) {
          console.error('Error:', error.message);
          reject(error);
        }
      }
    );
  });
};

export const getBodyHTMLAsync = () => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(
      Office.CoercionType.Html,
      (result) => {
        try {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            const currentBody = result.value;

            resolve(currentBody);
          } else {
            const errorMessage = 'Error getting body: ' + result.error.message;
            console.error(errorMessage);
            reject(new Error(errorMessage));
          }
        } catch (error) {
          console.error('Error:', error.message);
          reject(error);
        }
      }
    );
  });
};


//get the body content without the signature
export const getBodyWithoutSignatureAsync = () => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(
      Office.CoercionType.Html,
      (result) => {
        try {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            const currentHTML = result.value;
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(currentHTML, "text/html");

            //grab the signature, check for both ids before assuming it isnt present
            const signature = parsedHtml.querySelector('#Signature, [id$="Signature"]');
            if (signature) {
              signature.remove();
            }

            const currentBody = parsedHtml.documentElement.textContent.trim();

            resolve(currentBody);
          } else {
            const errorMessage = 'Error getting body: ' + result.error.message;
            console.error(errorMessage);
            reject(new Error(errorMessage));
          }
        } catch (error) {
          console.error('Error:', error.message);
          reject(error);
        }
      }
    );
  });
};

//extract the email signature from body onload
// if no signature is found return empty string
export const getEmailSignature = (bodyHtml) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(bodyHtml, 'text/html');

  let signature = parsedHtml.querySelector("#Signature");
  return signature;
};

export const formatBodyHtml = (text) => {
  return '<div class="elementToProof"><span ' +
    'style="letter-spacing: normal; font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif; font-size: 14.6667px; color: rgb(0, 0, 0); background-color: rgb(255, 255, 255); font-weight: 400;">' +
    text + '</span></div><br><br>';
}