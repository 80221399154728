import * as React from "react";
import { useState } from "react";
import TextInsertion from "./TextInsertion"; // Import your TextInsertion component
import TextSelection from "./TextSelection"; // Import your TextInsertion component

const TextManipulationContainer = () => {

  const [isTextInsertionVisible, setIsTextInsertionVisible] = useState(false);
  
  return (
    <div>
      <TextSelection />
      {isTextInsertionVisible && <TextInsertion />}
    </div>
  );
};

export default TextManipulationContainer;