import React, { useState } from "react";
import TextInsertion from "./TextInsertion";
import { Button, Field, makeStyles } from "@fluentui/react-components";
import { getSelectedText, getBodyAsync,getBodyWithoutSignatureAsync, getBodyHTMLAsync, setBodyHtmlAsync, getEmailSignature } from "../office-helpers";

//TODO - extract these styles into external css
const useStyles = makeStyles({
  container: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
  },
  header: {
    marginBottom: "10px",
    fontSize: "1.2em",
  },
  text: {
    fontWeight: "normal",
    fontSize: "1em",
  },
  error: {
    color: 'red',
    textAlign: "left",
  },
  promptInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  margin: {
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  linkActions: {
    display: "flex",
    justifyContent: "center",
  }
});

export const TextSelection = () => {
  //sets up styles
  const styles = useStyles();

  //selectedText is the value
  //setSelectedText sets the value 
  //and useState is the initial value
  const [selectedText, setSelectedText] = useState("");

  const [editedText, setEditedText] = useState(null);
  const [apiResponseParams, setApiResponseParams] = useState(null);
  const [isTextInsertionVisible, setIsTextInsertionVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [promptValue, setPromptValue] = useState('');
  const [insertType, setInsertType] = useState('');

  //this is to get your original body and signature upon opening the add-in 
  let originalBodyHtml;
  const fetchOriginalBody = async () => {
    try {
      originalBodyHtml = await getBodyHTMLAsync();
      //console.log(originalBodyHtml);

    } catch (error) {
      console.error('Error fetching original body:', error.message);
    }
  };
  fetchOriginalBody();

  const saveOnClick = async () => {
    setIsTextInsertionVisible(false);
    setEditedText(null);
  }

  //goes back to main screen and sets body to original body
  const cancelOnClick = async () => {
    try {
      await setBodyHtmlAsync(originalBodyHtml);
    } catch (error) {
      console.error('Error setting body:', error.message);
    }
    finally {
      setIsTextInsertionVisible(false);
      setEditedText(null);
    }
  };

  //TODO: update this to happen when the user clicks generate
  // ? what element does this pull from
  //sets the value or state of the prompt value to keep it updated
  const handleInputChange = (e) => {
    setPromptValue(e.target.value);
  };

  /**
   * *Call Lambda function to make OpenAI completions call and return message
   * @param {*} getTextFucntion - function used to get text from the email
   * @param {*} validateTextFunction - function used to validate text from email
   * TODO: abstract the getText/Validate function outside of the api call
   */
  const getChatCompletion = async (getTextFunction, validateTextFunction) => {
    try {
      setIsFetching(true);

      const text = await getTextFunction();
      setSelectedText(text);

      const errors = validateTextFunction(text);
      setValidationErrors(errors);

      if (errors.length === 0) {
        const response = await fetch('https://w37wy8jos9.execute-api.us-east-1.amazonaws.com/prod/complete-chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          // body: JSON.stringify({ content: text.data || text}),
          body: JSON.stringify({ content: text.data || text, prompt: promptValue }),
        });

        if (response.ok) {
          const responseData = await response.json();

          //after the response chatGPT sends line breaks, so <br> will convert each line break in <br>
          let generatedText = responseData.body.replace(/\n/g, "<br>");
          setEditedText(generatedText);
          setApiResponseParams(generatedText);
          setIsTextInsertionVisible(true);
        } else {
          console.error('Error:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsFetching(false);
    }
  };

  //before sending text to backend we need to determine what text we are selecting and what to validate
  const sendBodyText = () => {
    setInsertType('body');
    getChatCompletion(getBodyWithoutSignatureAsync, bodyTextValidated);

  };

  const sendSelectedText = () => {
    setInsertType('insert');
    getChatCompletion(getSelectedText, textSelectionValidated);
  };

  //validation for Rewrite selected Text button
  const textSelectionValidated = (selectedText) => {
    const errors = [];

    //need to extract data from selected text
    const textString = selectedText.data;

    if (!promptValue || promptValue.trim() === '') {
      errors.push('Please enter a prompt');
    }
    if (!textString || textString.trim() === '') {
      errors.push('Please highlight your text');
    }

    return errors;
  };

  //Validation for Rewrite Body button
  const bodyTextValidated = (selectedText) => {
    const errors = [];

    if (!promptValue || promptValue.trim() === '') {
      errors.push('Please enter a prompt');
    }

    if (!selectedText || selectedText.trim() === '') {
      errors.push('Please fill the body');
    }

    return errors;
  };

  return (
    <div className={styles.container}>
      {/* Errors */}
      {validationErrors.length > 0 && (
        <ul>
          {validationErrors.map((error, index) => (
            <li key={index} className={styles.error}>{error}</li>
          ))}
        </ul>
      )}

      {/*Input Prompt */}
      {!editedText && (
        <div className={styles.promptInput}>
          <h4 className={styles.margin}>Prompt</h4>
          <input
            type="text"
            value={promptValue}
            onChange={handleInputChange}
            style={{ width: '300px' }}
          />
        </div>
      )}

      {/* Buttons for Rewrite Body/Rewrite Selected Text */}
      {/*!editedText means this displays only if editedText is null  */}
      {!editedText && (
        <div className={styles.flex}>
          <Button appearance="primary" disabled={isFetching} size="medium" onClick={sendBodyText}>
            {isFetching ? "Please wait..." : "Rewrite Body"}
          </Button>
          <Button appearance="primary" disabled={isFetching} size="medium" onClick={sendSelectedText}>
            {isFetching ? "Please wait..." : "Rewrite Selected Text"}
          </Button>
        </div>
      )}

      {/* Edited Text */}
      {/* If edited text is not null - it will appear */}
      {editedText && (
        <Field className={styles.container} style={{ textAlign: "center" }}>
          <div className={styles.header}>
            <strong>Edited Text:</strong>
          </div>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: editedText }} />
        </Field>
      )}

      {/* Buttons for Insertion/Revert */}
      {isTextInsertionVisible && <TextInsertion apiResponseParams={apiResponseParams} selectedText={selectedText.data} insertType={insertType}/>}
      {isTextInsertionVisible && (
        <div className={styles.linkActions}>
          <a href="#" style={{ color: 'green', padding: "10px" }} onClick={saveOnClick}>Save</a>
          <a href="#" style={{ color: 'red', padding: "10px" }} onClick={cancelOnClick}>Cancel</a>
        </div>
      )}
    </div>
  );
};

export default TextSelection;