import * as React from "react";
import { useState } from "react";
import { Button, makeStyles } from "@fluentui/react-components";
import { getBodyHTMLAsync, insertTextAsync, setBodyHtmlAsync, formatBodyHtml } from "../office-helpers";
import { ScanTypeFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
  },
});

const TextInsertion = ({ apiResponseParams, selectedText, insertType }) => {
  const [previousBody, setPreviousBody] = useState(""); // State to store the current body
  const [revertEnabled, setRevertEnabled] = useState(false); // State to track if Revert button is clicked

  const insertTextOnClick = async () => {
    const body = await getBodyHTMLAsync();
    setPreviousBody(body); // Store the value of current body before inserting text
    console.log(insertType);
    console.log(typeof (body) + body)
    if (insertType === "insert") {
      insertTextAsync(apiResponseParams);
    }
    else { //assuming insert type is body
      try {
        let parser = new DOMParser();
        let parsedHtml = parser.parseFromString(body, "text/html");

        //grab the signature, check for both ids before assuming it isnt present
        const signature = parsedHtml.querySelector('#Signature, [id$="Signature"]');
        if (signature) {
          //if we have a signature, attach it to the new body
          //TODO: add css styles to div
          let bodyHtml = formatBodyHtml(apiResponseParams) + signature.outerHTML;
          setBodyHtmlAsync(bodyHtml);
        }
        else {
          //set the body
          //TODO: add css styles to div
          setBodyHtmlAsync(formatBodyHtml(apiResponseParams));
        }

      }
      catch (error) {
        console.error(error);
      }
    }

    setRevertEnabled(true);
  };

  //set the body back to the original copy
  const revertOnClick = () => {
    setBodyHtmlAsync(previousBody);
    setRevertEnabled(false);
  };

  const styles = useStyles();

  return (
    <div className={styles.buttons}>
      <Button appearance="primary" disabled={false} size="large" onClick={insertTextOnClick}>
        Insert
      </Button>
      <Button
        appearance="primary"
        disabled={!revertEnabled} // Disable the button if revertEnabled is false
        size="large"
        onClick={revertOnClick}
      >
        Revert
      </Button>
    </div>
  );
};

export default TextInsertion;
