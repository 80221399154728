import * as React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import { makeStyles } from "@fluentui/react-components";
import TextManipulationContainer from "./TextManipulationContainer";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App = (props) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Header logo="assets/logo-filled.png" title={props.title} message="OutlookGPT" />
      <TextManipulationContainer/>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
